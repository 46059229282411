<template>
  <div class="container">
    <b-form class="form">
      <div
        id="kt_subheader"
        class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid"
      >
        <div
          class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
        >
          <!--begin::Info-->
          <div class="d-flex align-items-center flex-wrap mr-2">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
              Product Management
            </h5>
            <!--end::Page Title-->
            <!--begin::Action-->
            <div
              class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
            ></div>

            <span class="text-muted font-weight-bold mr-4"> </span>
            <div
              class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
            ></div>

            <!--end::Action-->
          </div>

          <div class="d-flex align-items-center">
            <v-overlay :opacity="0.46" :value="isLoading">
              <v-progress-circular
                indeterminate
                size="36"
              ></v-progress-circular>
            </v-overlay>
          </div>
          <!--end::Info-->
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12"></div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <v-toolbar class="bg-dark" color="blue" dark>
            <v-toolbar-title>
              <div class="display-4  ">{{ name }}</div>
            </v-toolbar-title>

            <v-spacer> </v-spacer>
          </v-toolbar>
          <div class="card card-custom bg-alert ">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class=" row">
                    <label class="col-5 ">
                      <Strong>Assortments Used In </Strong></label
                    >
                    <div class="col-7">
                      {{ assortments.join() }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class=" row">
                    <label class="col-5 ">
                      <Strong>Date Created </Strong></label
                    >
                    <div class="col-7">
                      {{ created_at | date_format }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class=" row">
                    <label class="col-5 "> <Strong>Global Id </Strong></label>
                    <div class="col-7">
                      {{ $route.params.id }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-3  row">
                    <label class="col-7 col-form-label"
                      >Featured on Profile</label
                    >

                    <div class="col-5">
                      {{ featured }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row pt-2">
                <label class="col-5 col-form-label">Status</label>
                <div class="col-7">
                  {{ status }}
                </div>
              </div>

              <div class="form-group row">
                <label class="col-5 col-form-label">Name</label>
                <div class="col-7">
                  {{ name }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label">Part #</label>
                <div class="col-7">
                  {{ part_id }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-form-label"
                  >Use Tags to sort and identify your products:</label
                >

                <v-combobox
                  v-model="chips"
                  :items="tagNames"
                  chips
                  disabled
                  :search-input.sync="chipsearch"
                  label="Enter Tags followed by Enter key. (TAGS are a OR Logic)"
                  multiple
                  hide-selected
                  solo
                  @change="chipsearch = ''"
                >
                  <template
                    #selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      @click="select"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                  <template #no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No results matching "<strong>{{ chipsearch }}</strong
                          >". Press <kbd>enter</kbd> to create a new one
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <v-card class="h-100">
            <v-tabs horizontal>
              <v-tab>
                <v-icon left>mdi-access-point</v-icon>
                Main Image
              </v-tab>
              <v-tab>
                <v-icon left>mdi-access-point</v-icon>
                Additional Images
              </v-tab>
              <v-tab>
                <v-icon left>mdi-access-point</v-icon>
                Video Links
              </v-tab>

              <v-tab-item>
                <v-card class="mx-auto h-100">
                  <img
                    id="image_cover"
                    style="width: 100%; max-height: 480px;"
                    :src="
                      main_image_url != ''
                        ? baseurl + main_image_url
                        : '/media/product-placeholder.jpg'
                    "
                  />

                  <v-card-actions>
                    <v-btn text>Main Image</v-btn>
                    {{ main_image_name }}

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-row>
                    <v-col
                      v-for="(img, index) in additional_images"
                      :key="index"
                      cols="6"
                      sm="4"
                    >
                      <v-img
                        :src="
                          img.url != ''
                            ? baseurl + img.url
                            : 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg'
                        "
                      >
                        <div class="fill-height repeating-gradient"></div>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <div class="form-group row">
                      <label class="col-3 col-form-label text-dark"
                        >Youtube Link</label
                      >
                      <div class="col-7">
                        {{ youtube }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3 col-form-label text-dark"
                        >Vimeo Link</label
                      >
                      <div class="col-7">
                        {{ vimeo }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Product Type</label>
                    <div class="col-7">
                      {{ type }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label"
                      >Attribute Set for Items</label
                    >
                    <div class="col-7">
                      {{ attribute_set }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Secondary Name</label>
                    <div class="col-7">
                      {{ second_name }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Case Pack</label>
                    <div class="col-7">
                      {{ case_pack }}
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-5 col-form-label">Brand</label>
                    <div class="col-7">
                      {{ brand }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">URL Key</label>
                    <div class="col-7">
                      {{ url_key }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Weight LBS</label>
                    <div class="col-7">
                      {{ weight }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Length</label>
                    <div class="col-7">
                      {{ length_in }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Width</label>
                    <div class="col-7">
                      {{ width_in }}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-5 col-form-label">Height</label>
                    <div class="col-7">
                      {{ height_in }}
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-12 col-form-label"> Shipping Info</label>

                    <div class="col-12">
                      <span v-html="shipping_info"> </span>

                      <pre class="mt-3 mb-0"></pre>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-form-label"> Marketing text</label>

                    <div class="col-12">
                      <span v-html="marketing_text"> </span>

                      <pre class="mt-3 mb-0"></pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body px-3">
              <div class="form-group row">
                <label class="col-12 col-form-label"> Short Description</label>

                <div class="col-12">
                  <span v-html="short_description"></span>

                  <pre class="mt-3 mb-0"></pre>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-form-label"> Long Description</label>

                <div class="col-12">
                  <span v-html="long_description"></span>

                  <pre class="mt-3 mb-0"></pre>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="card card-custom mt-5">
              <div class="card-header">
                <div class="card-title">
                  Attribute Set Options
                  <small>** Attrtribute Set NAME here** </small>
                </div>
              </div>

              <div class="card-body">
                <template v-if="attribute_set == 'clothing'">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <label class="col-5 col-form-label">Style #</label>
                        <div class="col-7">
                          {{ clothing_style }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="attribute_set == 'demo1'">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <label class="col-5 col-form-label">Demo 1</label>
                        <div class="col-7">
                          {{ demo1 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="attribute_set == 'demo2'">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <label class="col-5 col-form-label">Demo 2</label>
                        <div class="col-7">
                          {{ demo2 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <v-toolbar color="cyan" dark flat>
          <v-toolbar-title> Items</v-toolbar-title>

          <v-spacer></v-spacer> <v-spacer></v-spacer>
        </v-toolbar>

        <b-table striped hover :items="items" :fields="fields">
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            {{ data.item.Name }}
          </template>
          <template #cell(sku)="data">
            {{ data.item.sku_id }}
          </template>
          <template #cell(actions)="data">
            <v-btn @click="$router.push('/pim/item-view/' + data.item.id)">
              View
            </v-btn>
          </template>
        </b-table>
        <div class="row mt-8">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="limit"
            style="margin: 0 auto;"
          ></b-pagination>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";

import { validationMixin } from "vuelidate";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import gql from "graphql-tag";

import GetCompanyTags from "@/graphql/queries/GetCompanyTags.gql";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
  },
  mixins: [validationMixin],
  data: () => ({
    clothing_style: "",
    demo1: "",
    demo2: "",
    chipsearch: "",
    isLoading: false,
    assortments: [],
    youtube: "",
    vimeo: "",
    main_image: null,
    additional_image: null,
    additional_images: [],
    main_image_url: "",
    main_image_name: "",
    main_image_id: "",
    limit: 10,
    currentPage: 1,
    totalCount: 0,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => "The email and password you entered don't match",
    },
    name: "",
    part_id: "",
    case_pack: "",
    url_key: "",
    weight: "",
    length_in: "",
    width_in: "",
    height_in: "",
    shipping_info: "",
    marketing_text: "",
    brand: "",
    second_name: "",
    created_at: "",
    status: "new",
    product_options: [
      { value: "new", text: "New" },
      { value: "pending", text: "Pending" },
      { value: "discontinued", text: "Discontinued" },
      { value: "active", text: "Active" },
    ],
    type: "",
    type_options: [
      { value: "", text: "Choose Type" },
      { value: "simple", text: "Simple" },
      { value: "has_variants", text: "Has Variants" },
      { value: "virtual", text: "Virtual" },
    ],
    attribute_set: "",
    attribute_set_options: [
      { value: "", text: "Choose Set of Attributes" },
      { value: "clothing", text: "Clothing" },
      { value: "demo1", text: "Demo 1" },
      { value: "demo2", text: "Demo 2" },
    ],
    chips: [],
    featured: false,
    short_description: "",
    long_description: "",
    fields: [
      {
        key: "index",
        label: "#",
      },
      {
        key: "name",
        label: "Name",
      },
      {
        key: "sku",
        label: "Sku #",
      },
      {
        key: "actions",
        label: "Actions",
      },
    ],
    items: [],
    addDialog: false,
    addDialogformvalid: false,
    addDialogform: {
      name: "",
      skuid: "",
    },
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",

    editor: ClassicEditor,

    editorConfig: {
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "undo",
          "redo",
        ],
      },
    },
    options: [
      { value: null, text: "New" },
      { value: "draft", text: "Draft" },
      { value: "awaiting", text: "Awaiting Approval" },
      { value: "active", text: "Active " },
      { value: "disco", text: "Discontinued" },
      { value: "eol", text: "End of Life" },
    ],
    logic: ["Active", "Pending", "Discontinued", "NOT Discontinued"],

    dialog: false,
    dialog2: false,
    tagHeader: [{ header: "Select an option or create one" }],
  }),
  computed: {
    tagNames: function() {
      const allTags = this.tags ? this.tags.map((t) => t.name) : [];
      return [].concat(this.tagHeader, allTags);
    },
  },
  apollo: {
    tags: {
      query: GetCompanyTags,
      variables() {
        return {
          companyId: this.$store.state.auth.user.company_relation,
        };
      },
    },
    items: {
      query: gql`
        query Items($product: Int, $limit: Int, $start: Int) {
          items: items(
            limit: $limit
            start: $start
            where: { product: { id: $product } }
          ) {
            id
            Name
            sku_id
          }
        }
      `,
      variables() {
        return {
          product: parseInt(this.$route.params.id),
          limit: this.limit,
          start: (this.currentPage - 1) * this.limit,
        };
      },
    },
  },
  created() {
    if (this.$route.params.id != "" && this.$route.params.id != undefined) {
      setTimeout(() => {
        this.checkProductAccess();
      }, 1000);

      this.getProductById({
        id: this.$route.params.id,
      }).then((res) => {
        if (res.data != false) {
          this.assortments = res.data.assortments.map((a) => a.Name);
          this.name = res.data.name;
          this.part_id = res.data.part_id;
          this.created_at = res.data.created_at;
          this.status = res.data.status;
          this.case_pack = res.data.case_pack;
          this.featured = res.data.featured;
          this.short_description = res.data.short_description;
          this.long_description = res.data.long_description;
          this.youtube = res.data.youtube;
          this.vimeo = res.data.vimeo;
          this.url_key = res.data.url_key;
          this.weight = res.data.weight;
          this.length_in = res.data.length_in;
          this.width_in = res.data.width_in;
          this.height_in = res.data.height_in;
          if (
            res.data.shipping_info != undefined &&
            res.data.shipping_info != null
          ) {
            this.shipping_info = res.data.shipping_info;
          }
          if (
            res.data.marketing_text != undefined &&
            res.data.marketing_text != null
          ) {
            this.marketing_text = res.data.marketing_text;
          }
          this.brand = res.data.brand;
          this.second_name = res.data.second_name;
          if (res.data.main_image != null) {
            this.main_image_id = res.data.main_image.id;
            this.main_image_url = res.data.main_image.url;
            this.main_image_name =
              res.data.main_image.name + res.data.main_image.ext;
          }
          if (res.data.additional_image != null) {
            this.additional_images = res.data.additional_image;
          }
          if (res.data.tags !== null) {
            this.chips = res.data.tags.map((t) => t.name);
          }

          this.type = res.data.type;
          this.attribute_set = res.data.attribute_set;
        } else {
          this.$router.push("/pim/list");
        }
      });

      this.getItemTotalCount({
        params: "product=" + this.$route.params.id,
      }).then((res) => {
        this.totalCount = res.data;
      });
    }
  },
  methods: {
    ...mapActions([
      "getAssortmentProductTotalCount",
      "getAssortmentsBySupplier",
      "getProductById",
      "deleteFile",
      "removeProduct",
      "getItemTotalCount",
      "removeItem",
      "updateProductAdditionalImg",
      "getProductByAssortment",
      "getChannelAccessWhere",
      "getChannelAccessTotalCount",
      "getCategories",
    ]),
    async checkProductAccess() {
      let res = await this.getProductById({
        id: this.$route.params.id,
      });
      let resc = await this.getChannelAccessWhere({
        where:
          "?_where[0][channel.supplier]=" +
          res.data.supplier.id +
          "&_where[1][seller_company]=" +
          this.$store.state.auth.user.company_relation,
      });
      let flag = 0;
      for (let i in resc.data) {
        flag = await this.hasProductInChannel(
          resc.data[i].channel.id,
          this.$route.params.id
        );
        if (flag == 1) break;
      }
      if (flag == 0) {
        this.$router.push("/buyer/mypartners");
      }
    },
    async hasProductInChannel(channel) {
      let resct = await this.getCategories({
        channel: channel,
      });
      for (let j in resct.data) {
        if (
          resct.data[j].assortment != null &&
          resct.data[j].assortment != undefined
        ) {
          let res = await this.getAssortmentProductTotalCount({
            params:
              "assortments=" +
              resct.data[j].assortment.id +
              "&id=" +
              this.$route.params.id,
          });
          if (res.data > 0) {
            return 1;
          }
        }
      }
      return 0;
    },
    addimageUpload() {
      if (this.additional_image != null) {
        this.updateProductAdditionalImg({
          id: this.$route.params.id,
          additional_image: this.additional_image,
        }).then(() => {
          this.refreshAdditionalImg();
        });
      }
    },
    refreshAdditionalImg() {
      this.getProductById({
        id: this.$route.params.id,
      }).then((res) => {
        if (res.data.additional_image != null) {
          this.additional_images = res.data.additional_image;
        }
      });
    },
    removeImg(id) {
      if (id != "" && id != undefined) {
        this.$bvModal
          .msgBoxConfirm("Are you sure?", {
            title: "Confirm",
            size: "sm",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.deleteFile({
                id: id,
              }).then(() => {
                this.refreshAdditionalImg();
              });
            }
          });
      }
    },
    imageChange(e) {
      let target = e.target || e.srcElement;
      let files = target.files;
      let fr = new FileReader();
      fr.onload = function() {
        document.getElementById("image_cover").src = fr.result;
      };
      fr.readAsDataURL(files[0]);
    },
    imageDelete() {
      this.main_image = "";
      this.main_image_name = "";
      this.main_image_url = "";
    },
    addDialogClose() {
      this.addDialogform.name = "";
      this.addDialogform.skuid = "";
      this.addDialog = false;
    },
    removeChip(item) {
      let index = this.chips.indexOf(item);
      if (index > -1) {
        this.chips.splice(index, 1);
      }
    },
    removeProductBtn() {
      if (this.$route.params.id != "" && this.$route.params.id != undefined) {
        this.$bvModal
          .msgBoxConfirm("Are you sure?", {
            title: "Confirm",
            size: "sm",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.removeProduct({
                id: this.$route.params.id,
              }).then(() => {
                Swal.fire({
                  title: "",
                  text: "The product has been successfully removed!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });

                this.$router.push("/pim/list");
              });
            }
          });
      }
    },
    removeItemBtn(id) {
      if (id != "" && id != undefined) {
        this.$bvModal
          .msgBoxConfirm("Are you sure?", {
            title: "Confirm",
            size: "sm",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.removeItem({
                id: id,
              }).then(() => {
                Swal.fire({
                  title: "",
                  text: "The item has been successfully removed!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.refreshItems();
              });
            }
          });
      }
    },
    refreshItems() {
      this.$apollo.queries.items.refetch();

      this.getItemTotalCount({
        params: "product=" + this.$route.params.id,
      }).then((res) => {
        this.totalCount = res.data;
      });
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
